<template>
  <div class="position-relative pb-1">
    <ColorForm :title="'Create Color'" action="Save"/>
  </div>
</template>

<script>
import ColorForm from "@/views/components/colors/ColorForm";

export default {
  name: "Create",
  components: {
    ColorForm
  },
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
